<template>
  <div class="recharge-wrap" v-if="auth !== null"> 
    <div class="bread-wrap">
      <span class="bread-title">账户充值</span>
    </div>

    <div class="content">
      <template v-if="auth">
        <el-tabs :value="activeName" @input="handleTabChange">
          <el-tab-pane label="快捷充值" name="online">
            <on-line />
          </el-tab-pane>
          <el-tab-pane label="银行汇款充值" name="offline">
            <off-line />
          </el-tab-pane>
        </el-tabs>
      </template>
      <template v-else>
        <div class="no-auth">
          <el-card class="no-auth-card">
            <div class="auth-header">
              <i class="el-icon-lock auth-icon"></i>
              <p class="auth-title">实名认证后可进行充值</p>
            </div>
            <p class="auth-message">
              为了保障您的账户安全，并享受更优质的充值体验，请先完成实名认证。
            </p>
            <el-button type="primary" @click="goToAuth" round>
              <i class="el-icon-check"></i> 去认证
            </el-button>
          </el-card>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import OnLine from "./OnLine/index.vue";
import OffLine from "./OffLine/index.vue";

export default {
  data() {
    return {
      activeName: "online",
      auth: null,
    };
  },
  mounted() {
    this.getAuthStatus();
  },
  methods: {
    getAuthStatus() {
      // 调接口获取用户信息
      this.$store.dispatch("user/getuserinfo").then((res) => {
        if (res.RetCode === 0) {
          this.auth = res.Authenticated;
        } else {
          this.auth = false; // 确保有默认值
        }
      }).catch(() => {
        this.auth = false; // 请求失败时也要赋值，避免页面一直等
      });
    },
    goToAuth() {
      this.$router.push("/user/enterprise");
    },
    handleTabChange(value) {
      this.activeName = value;
    }
  },
  components: { OnLine, OffLine },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";

.no-auth {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.no-auth-card {
  width: 420px;
  padding: 30px;
  text-align: center;
  background: linear-gradient(135deg, #12aff2, #11d5ef);
  border-radius: 12px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  color: #ffffff;
}

.auth-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.auth-icon {
  font-size: 36px;
  color: #ffffff;
  margin-right: 10px;
}

.auth-title {
  font-size: 22px;
  font-weight: bold;
}

.auth-message {
  font-size: 16px;
  margin: 10px 0 20px;
}

.el-button {
  font-size: 16px;
  padding: 10px 20px;
}
</style>